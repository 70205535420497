import { Component, IComponentConstructor, StatelessComponent } from 'inferno'
import { createElement } from 'inferno-create-element'
import 'whatwg-fetch'

import { DynamicProps } from '@components/dynamic'
import { Loader } from '@components/loader'

type GenericObject = { [key: string]: any };

interface State {
  data: GenericObject|null
}

export const asyncComponent = (WrappedComponent: StatelessComponent<any>|IComponentConstructor<any>, props: GenericObject, fetchUrl: string) => (
  class AsyncComponent extends Component<DynamicProps, State> {

    constructor() {
      super()

      this.fetch = this.fetch.bind(this)

      this.fetch()
    }

    public state: State = {
      data: null
    }

    fetch() {
      fetch(fetchUrl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin'
      })
      .then(response => response.json())
      .then((data: JSON): void => {
        this.setState({ data })
      })
    }

    render() {
      return !this.state.data ?
        <Loader /> : createElement(WrappedComponent, { ...props, ...this.state.data })
    }
  }
)
