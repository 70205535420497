import { Component, FormEvent } from 'inferno'
import isString from 'lodash/isString'
import isObject from 'lodash/isObject'
import isNumber from 'lodash/isNumber'
import uniq from 'lodash/uniq'
import classNames from 'classnames'
import 'whatwg-fetch'

import { ActionMap, ActionList, IAction } from '@components/action-list'
import { IToast, TToastType } from '@components/toaster'
import { Pagination, Props as PaginationProps } from '@components/pagination'
import { ModalFnParams } from '@/types'

type sortOrder = 'asc' | 'desc'

interface IActionResponse {
  message: string
  type: TToastType
  data?: IDataResponse
}

interface IDataResponse {
  created?: Row[]
  updated?: Row[]
  deleted?: number[]
}

interface Column {
  key: string
  content: string
  sortable?: boolean
}

type Row = ComplexRow | SimpleRow

type ComplexRow = {
  config?: {
    classname?: string
  }
  content: Cell[]
}

type SimpleRow = Cell[]

interface ActionCell {
  content: IAction[]|ActionMap
  type: "actions"
}

interface BaseCell {
  content: number|string
  sort?: number|string
  rawcontent?: number|string
  type?: "text" | "base" | "number"
}

type Cell = BaseCell | ActionCell

interface RowActions {
  row?: ActionMap
  rowAdditional?: ActionMap
  bulk?: ActionMap
}

interface Config {
  defaultItemsPerPage?: number
  defaultSortColumn?: string
  defaultSortOrder?: sortOrder
  keyColumn?: string|null
  filters: TFilterConfig[]
  hiddenColumns: string[]
  actions?: RowActions
}

interface TableData {
  columns: Column[]
  rows: Row[]
  config: Config
}

interface Props extends TableData {
  checkedRows?: number[]
  filteredRows?: number[]
  tableClassName?: string
}

type TFilterConfig = IFilterConfigString | IFilterConfigBoolean

interface IFilterConfigString {
  column: string
  type: 'string'
  label: string
  default?: string
}

interface IFilterConfigBoolean {
  column: string
  type: 'boolean'
  labelAll: string
  labelTrue: string
  labelFalse: string
  default?: boolean
}

interface State {
  displayColumns: Column[]
  rows: Row[]
  filteredRows: Row[]
  sortedRows: Row[]
  checkedRows: number[]
  loading: boolean
  currentPage: number
  itemsPerPage: number
  sortCol: string|null
  sortOrder: sortOrder|null
  visibleColumns?: string[]|null
  activeFilters: TRowFilter[]
}

type TRowFilter = IStringFilter | IBooleanFilter

interface IStringFilter {
  type: 'string'
  column: string
  value: string
}

interface IBooleanFilter {
  type: 'boolean'
  column: string
  value: boolean|null
}

function getRowContent(row: Row) {
  return Array.isArray(row) ? row : row.content
}

function getCellSortContent(cell: Cell) {
    let cellContent: string|number|[]|undefined

    if ((cell as BaseCell).sort) {
        cellContent = (cell as BaseCell).sort
    }
    else if ((cell as BaseCell).rawcontent) {
        cellContent = (cell as BaseCell).rawcontent
    }
    else {
        cellContent = cell.content
    }

    if (!cellContent) {
        return 0
    }
    else if (typeof cellContent === 'string') {
        return cellContent.toLowerCase()
    }
    else {
        return cellContent
    }
}

function sortRows(rows: Row[], sortColIndex: number, sortOrder: sortOrder|null) {

  if (!rows || !rows.length || !isNumber(sortColIndex) || !sortOrder) {
    return rows
  }

  rows.sort((a, b) => {
    const aCell: Cell = getRowContent(a)[sortColIndex]
    const bCell: Cell = getRowContent(b)[sortColIndex]

    let aCellContent: string|number|[] = getCellSortContent(aCell) || 0
    let bCellContent: string|number|[] = getCellSortContent(bCell) || 0

    if (aCellContent > bCellContent) { return sortOrder == 'desc' ? -1 : 1 }
    if (aCellContent < bCellContent) { return sortOrder == 'desc' ?  1 : -1 }

    return 0;
  });

  return rows
}

function addToast(toast: IToast) {
    window.Toaster.addToast(toast)
}

export class ManageTable extends Component <Props, State> {

  public state: State = {
    displayColumns: [],
    rows: [],
    sortedRows: [],
    filteredRows: [],
    visibleColumns: null,
    checkedRows: [],
    loading: true,
    currentPage: 0,
    itemsPerPage: 20,
    sortCol: null,
    sortOrder: null,
    activeFilters: []
  }

  constructor() {
    super()

    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleTableHeaderClick = this.handleTableHeaderClick.bind(this)
    this.handleCheckRows = this.handleCheckRows.bind(this)
    this.handleActions = this.handleActions.bind(this)
    this.handleCheckedInfoClick = this.handleCheckedInfoClick.bind(this)
    this.handleItemsPerPageChange = this.handleItemsPerPageChange.bind(this)
  }

  componentDidMount() {
    this.initialize(this.props)
  }

  initialize(props: Props) {

    this.validateProps(props)

    const config = this.props.config
    const propsRows = this.props.rows || []
    const checkedRows = this.props.checkedRows || []

    const itemsPerPage = config.defaultItemsPerPage || this.state.itemsPerPage
    const sortCol = !config.defaultSortColumn || this.state.sortCol ? this.state.sortCol : config.defaultSortColumn
    const sortColIndex = this.getSortColIndex(sortCol, props.columns)
    const displayColumns = this.getDisplayColumnsFromProps()
    const sortOrder = !config.defaultSortOrder || this.state.sortOrder ? this.state.sortOrder : config.defaultSortOrder

    const rows = [ ...propsRows ]
    const activeFilters: TRowFilter[] = []
    let filteredRows = rows

    if (config.filters && config.filters.length) {
      config.filters.forEach(filter => {
        if (filter.default) {
          activeFilters.push({
            type: filter.type,
            column: filter.column,
            value: filter.default
          })
        }
      })

      filteredRows = this.filterRows(rows, activeFilters)
    }

    const sortedRows = !sortColIndex ? filteredRows : sortRows(filteredRows, sortColIndex, sortOrder)

    this.setState({
      activeFilters,
      displayColumns, rows, sortCol, sortOrder, checkedRows, itemsPerPage,
      sortedRows: sortedRows
    })
  }

  validateProps(props: Props) {

    if (Object.keys(props).includes('error')) {
      throw new Error('[ManageTable] ' + (props as any).error)
    }

    if (!props.config) {
      throw new Error('[ManageTable] Config data missing.')
    }
  }

  /**
   * Data handling
   */

  filterRows(rows: Row[], filters: TRowFilter[]) {

    let filteredRows: Row[] = rows

    filters.forEach(filter => {
      if (filter.type == 'string') {
        filteredRows = filteredRows.filter(row => {
          let isMatch = false
          const filterColIndex = this.getColIndex(filter.column)
          const filterValue = filter.value.toLowerCase()
          let filterField: string
          getRowContent(row).forEach((cell, index) => {
            filterField = cell.type !== 'actions' && cell.rawcontent ?
              'rawcontent' : 'content'

            if (index === filterColIndex && typeof cell[filterField] === 'string') {
              isMatch = cell[filterField].toLowerCase().includes(filterValue)
            }
          })
          return isMatch
        })
      }
      else if (filter.type == 'boolean') {
        filteredRows = filteredRows.filter(row => {
          let isMatch = false
          const filterColIndex = this.getColIndex(filter.column)
          let filterField: string
          getRowContent(row).forEach((cell, index) => {
            filterField = cell.type !== 'actions' && cell.rawcontent !== undefined ?
              'rawcontent' : 'content'

            if (index === filterColIndex && typeof cell[filterField] === 'boolean') {
              isMatch = cell[filterField] === filter.value
            }
          })
          return isMatch
        })
      }
    })

    return filteredRows
  }

  updateFilters(filterUpdates: { filterConfigItem: TFilterConfig, value: any }[]) {

    const { activeFilters, rows, sortCol, sortOrder } = this.state
    let newFilters: TRowFilter[] = activeFilters

    filterUpdates.forEach(({ filterConfigItem, value }) => {
      const { type, column } = filterConfigItem
      const includesFilter = activeFilters.find(filter => filter.column === column)

      if ((type === 'string' && value !== '') || (type === 'boolean' && value !== null)) {

        const newFilter: TRowFilter = {
          type, column, value
        }

        newFilters = !includesFilter ?
          [ ...activeFilters, newFilter ] :
          activeFilters.map(filter => filter.column === column ? newFilter : filter)
      }
      else {
        newFilters = !includesFilter ?
          activeFilters :
          activeFilters.filter(filter => filter.column !== column)
      }
    })

    const filteredRows = this.filterRows(rows, newFilters)
    const sortColIndex = this.getSortColIndex(sortCol, this.props.columns)
    const sortedRows = !sortColIndex ? filteredRows : sortRows(filteredRows, sortColIndex, sortOrder)

    this.setState({
      currentPage: 0,
      activeFilters: newFilters,
      filteredRows,
      sortedRows, 
    })
  }

  /**
   * Action handlers
   */

  handlePageClick(page: 'prev'|'next'|number) {
    const { currentPage } = this.state

    switch(page) {
      case 'next': return this.setState({ currentPage: currentPage+1 })
      case 'prev': return this.setState({ currentPage: currentPage-1 })
      default:     return this.setState({ currentPage: page })
    }
  }

  handleBooleanFilterChange(filterConfigItem: TFilterConfig, e: FormEvent<HTMLInputElement>) { let value: string|boolean|null = (e.target as HTMLInputElement).value

    if (filterConfigItem.type === 'boolean') {
      switch (value) {
        case '1': value = true; break
        case '0': value = false; break
        default:  value = null; break
      }
    }

    this.updateFilters([{ filterConfigItem, value }])
  }

  handleStringFilterChange(filterConfigItem: TFilterConfig, e: FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value

    this.updateFilters([{ filterConfigItem, value }])
  }

  handleDataresponse(data: IDataResponse) {

    const newRows = [ ...this.state.rows ]

    if (data.updated) {
      console.debug('---------- updated ----------')
      data.updated.forEach((row: Row) => {
        const itemId = this.getRowIdFromRow(row)

        if (itemId && !isNaN(itemId)) {
          const rowIndex = this.getRowIndexById(itemId)

          if (isNumber(rowIndex)) {
            newRows[rowIndex] = row
          }
        }
      })
    }

    if (data.created) {
      console.debug('---------- created ----------')
      data.created.forEach((row: Row) => {
        const itemId = this.getRowIdFromRow(row)

        if (isNumber(itemId)) {
          const rowIndex = this.getRowIndexById(itemId)

          if (rowIndex === -1) {
            newRows.push(row)
          }
        }
      })

      sortRows(newRows, this.getSortColIndex(), this.state.sortOrder)
    }

    if (data.deleted) {
      console.debug('---------- deleted ----------')
      data.deleted.forEach((id: number) => {
        const rowIndex = this.getRowIndexById(id, newRows)
        if (isNumber(rowIndex)) {
          newRows.splice(rowIndex, 1)
        }
      })
    }

    return newRows
  }

  handleCheckRowsVisible(e: Event) {
    e.preventDefault()
    this.checkRowsVisible()
  }

  handleCheckRows(e: Event, rows: Row[]) {
    e.preventDefault()
    this.checkRows(rows)
  }

  handleTableHeaderClick(e: Event, column: Column) {
    e.preventDefault()
    e.stopPropagation()

    const { sortCol, sortOrder } = this.state

    const newSortColKey = column.key
    const newSortColIndex = this.getSortColIndex(newSortColKey) || 0
    const newSortOrder = (sortCol !== newSortColKey) || sortOrder == 'asc' ? 'desc' : 'asc'
    const sortedRows = sortRows(this.state.sortedRows, newSortColIndex, newSortOrder) 

    this.setState({
      sortedRows,
      sortCol: newSortColKey,
      sortOrder: newSortOrder
    })
  }

  handleItemsPerPageChange(number: number) {
    this.setState({
      itemsPerPage: number
    })
  }

  handleActions(e: Event, action: IAction, itemId?: number) {
    if (action.type == 'get') {
      return
    }

    e.preventDefault()

    if (action.confirmText && !confirm(action.confirmText)) {
      return
    }

    switch (action.type) {
      case 'async': { this.handleAsyncAction(action, itemId) } break
      case 'post':  { this.handlePostAction(action, itemId) } break
    }
  }

  handlePostAction(action: IAction, itemId?: number) {
    const itemIds = itemId ? [ itemId ] : this.state.checkedRows
    if (action.url) {
      post(action.url, { ids: itemIds, test: 123 })
    }
  }

  handleAsyncAction(action: IAction, itemId?: number) {
    if (action.url) {
      const itemIds = itemId ? [ itemId ] : this.state.checkedRows
      fetch(`${action.url}`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ids: itemIds
        })
      })
      .then(response => response.json())
      .then((response: IActionResponse): void => {
        const sc: State = {
          ...this.state,
          checkedRows: []
        }
        addToast({
          text: response.message,
          type: response.type
        })
        if (response.data) {
          sc.rows = this.handleDataresponse(response.data)
          sc.filteredRows = this.filterRows(sc.rows, this.state.activeFilters)
          sc.sortedRows = sortRows(sc.filteredRows, this.getSortColIndex(), this.state.sortOrder)
          sc.currentPage = this.getFixedCurrentPage(sc.sortedRows)
        }
        this.setState(sc)
      })
    }
  }

  /**
   * Getters
   */

  getSortColIndex(sortCol?: string|null, columns?: Column[]) {
    sortCol = sortCol || this.state.sortCol
    return !sortCol ? 0 : this.getColIndex(sortCol, columns)
  }

  getKeyColIndex(keyCol?: string|null, columns?: Column[]) {
    keyCol = keyCol || this.props.config.keyColumn
    return !keyCol ? 0 : this.getColIndex(keyCol, columns)
  }

  getColIndex(columnKey: string, columns?: Column[]) {
    columns = columns || this.props.columns
    return columns.findIndex(column => column.key == columnKey)
  }

  getDisplayColumnsFromProps(props: Props = this.props) {
    const { columns: propsColumns } = props

    const columns = !props.config.hiddenColumns ?
      propsColumns : propsColumns.filter(column => !props.config.hiddenColumns.includes(column.key))

    return columns
  }

  getTotalPages(rows?: Row[]) {
    const { sortedRows, itemsPerPage } = this.state
    rows = rows || sortedRows
    return Math.ceil(rows.length / itemsPerPage)
  }

  getPaginatedRows() {

    const { itemsPerPage } = this.state
    const { currentPage, sortedRows } = this.state

    if (itemsPerPage !== -1) {
      let firstItem = currentPage * itemsPerPage

      if(sortedRows && sortedRows.length) {
        return sortedRows.slice(firstItem, firstItem + itemsPerPage)
      }
    }

    return sortedRows
  }

  get showRowCheckboxes() {
    const { config } = this.props

    return config.actions && config.actions.bulk && Object.keys(config.actions.bulk).length
  }

  getFixedCurrentPage(rows?: Row[]) {
    const { currentPage, sortedRows } = this.state
    const totalPages = this.getTotalPages(rows)

    rows = rows || sortedRows

    return currentPage >= totalPages ?
      totalPages-1 : currentPage
  }

  getRowIdFromRow(row: Row) {
    const keyColIndex = this.getKeyColIndex()

    return !isNumber(keyColIndex) ? null :
      getRowContent(row)[keyColIndex].content as number
  }

  getRowIndexById(id: number, rows?: Row[]) {
    rows = rows || this.state.rows
    const keyColIndex = this.getKeyColIndex()

    return !isNumber(keyColIndex) ? null :
      rows.findIndex(row => getRowContent(row)[keyColIndex].content == id)
  }

  checkRows(rows: Row[]) {
    const keyColIndex = this.getKeyColIndex()

    if (isNumber(keyColIndex)) {
      const rowIds = rows.map(row => getRowContent(row)[keyColIndex].content as number)

      if (rowIds.every(row => this.state.checkedRows.includes(row))) {
        this.setState({ checkedRows: [ ...this.state.checkedRows.filter(id => !rowIds.includes(id)) ] })
      }
      else {
        this.setState({ checkedRows: uniq([ ...this.state.checkedRows, ...rowIds ]) })
      }
    }
  }

  handleCheckedInfoClick(e: Event) {
    e.preventDefault()
    this.uncheckAll()
  }

  uncheckAll() {
    this.setState({
      checkedRows: []
    })
  }

  areRowsChecked(rows: Row[]) {

    if (!rows || !rows.length) {
      return
    }

    const keyColIndex = this.getKeyColIndex()

    if (isNumber(keyColIndex)) {
      return rows.every(row => (
        this.state.checkedRows.includes(getRowContent(row)[keyColIndex].content as number)
      ))
    }

    return false
  }

  checkRowsVisible() {
    const paginatedRows = this.getPaginatedRows()
    this.checkRows(paginatedRows)
  }

  getActionsFromMap(actionmap: ActionMap): IAction[] {
    return Object.keys(actionmap).map(key => ({
      name: key,
      ...actionmap[key]
    }))
  }

  /**
   * Rendering
   */

  render() {
    const { columns, tableClassName } = this.props

    if (!columns) {
      return null
    }

    const classes = classNames(
      'manage-table', tableClassName
    )

    return (
      <div className={classes}>

        { this.renderFilters() }
        { this.renderPagination() }

        <table className="table table-hover manage-table">
          { this.renderTableHeader() }
          { this.renderTableBody() }
        </table>

        { this.renderTableSummary() }

        { this.renderBulkActions() }
        { this.renderCheckedinfo() }
      </div>
    )
  }

  renderTableHeader() {
    const { displayColumns: columns, sortCol, sortOrder } = this.state

    let colProps:{
      className: string
      onClick?: (e: MouseEvent|TouchEvent) => void
    }

    return (
      <thead>
        <tr>
          {
            !this.showRowCheckboxes ? null : (
              <th className="check-all"><input type="checkbox" checked={this.areRowsChecked(this.getPaginatedRows())} onChange={e => this.handleCheckRowsVisible(e)}/></th>
            )
          }
          {
            columns.map((column: Column, i: number) => {

              const isSorting = column.key == sortCol

              colProps = {
                className: classNames(
                  column.key,
                  {
                    [`sorting ${sortOrder}`]: isSorting,
                    'sortable': column.sortable || column.sortable === undefined
                  }
                )
              }

              if(column.sortable || column.sortable === undefined) {
                colProps.onClick = e => this.handleTableHeaderClick(e, column)
              }

              const headerSortIndicator = !isSorting ? null : (
                <svg class="icon" viewBox="0 0 24 24">
                  <use xlinkHref={`${window.M.util.image_url('icons', 'local_managetable')}#${sortOrder == 'desc' ? 'chevron-down' : 'chevron-up'}`} />
                </svg>
              )

              return <th key={`th-${i}`} {...colProps}>{column.content} {headerSortIndicator}</th>
            })
          }
        </tr>
      </thead>
    )
  }

  renderFilters() {
    const { activeFilters } = this.state
    const { config } = this.props

    if (!config.filters) {
      return
    }

    const filterNodes = config.filters.map(filterConfigItem => {
      const activeFilter = activeFilters.find(filter => filter.column == filterConfigItem.column)

      switch (filterConfigItem.type) {

        case 'string': {
          return (
            <div class="col">
              <div className={`form-group filter-item filter-item--string filter__${filterConfigItem.column}`}>
                <label className="sr-only" htmlFor={`filter-input__${filterConfigItem.column}`}> {filterConfigItem.label} </label>
                <input type="text"
                  className="form-control"
                  placeholder={filterConfigItem.label}
                  value={activeFilter ? activeFilter.value as string : ''}
                  id={`filter-input__${filterConfigItem.column}`}
                  name={`filter-input__${filterConfigItem.column}`}
                  onInput={(e) => this.handleStringFilterChange(filterConfigItem, e)}
                />
              </div>
            </div>
          )
        }

        case 'boolean': {
          return (
            <div class="col">
              <div className={`form-group filter-item filter-item--boolean filter__${filterConfigItem.column}`}>

                <div className="filter-item__radio form-check form-check-inline">
                  <input type="radio"
                    className="form-check-input"
                    id={`filter-radio__${filterConfigItem.column}--all`}
                    name={`filter-radio__${filterConfigItem.column}`}
                    value="-1"
                    checked={!activeFilter}
                    onChange={(e) => this.handleBooleanFilterChange(filterConfigItem, e)}
                  />
                  <label className="form-check-label" htmlFor={`filter-radio__${filterConfigItem.column}--all`}>{filterConfigItem.labelAll}</label>
                </div>

                <div className="filter-item__radio form-check form-check-inline">
                  <input type="radio"
                    className="form-check-input"
                    id={`filter-radio__${filterConfigItem.column}--true`}
                    name={`filter-radio__${filterConfigItem.column}`}
                    value="1"
                    checked={activeFilter && activeFilter.value === true}
                    onChange={(e) => this.handleBooleanFilterChange(filterConfigItem, e)}
                  />
                  <label className="form-check-label" htmlFor={`filter-radio__${filterConfigItem.column}--true`}>{filterConfigItem.labelTrue}</label>
                </div>

                <div className="filter-item__radio form-check form-check-inline">
                  <input type="radio"
                    className="form-check-input"
                    id={`filter-radio__${filterConfigItem.column}--false`}
                    name={`filter-radio__${filterConfigItem.column}`}
                    value="0"
                    checked={activeFilter && activeFilter.value === false}
                    onChange={(e) => this.handleBooleanFilterChange(filterConfigItem, e)}
                  />
                  <label className="form-check-label" htmlFor={`filter-radio__${filterConfigItem.column}--false`}>{filterConfigItem.labelFalse}</label>
                </div>

              </div>
            </div>
          )
        }

      }
    })

    return (
      <div className="manage-table__filters form-row">
        { filterNodes }
      </div>
    )
  }

  renderPagination() {
    const { sortedRows, currentPage, itemsPerPage } = this.state
    const { config } = this.props

    if(!sortedRows || !sortedRows.length) {
      return
    }

    const totalPages = this.getTotalPages()

    const itemsPerPageOptions = [ -1, 25, 50, 100 ]

    // Make sure default items per page are in pagination options
    if (config.defaultItemsPerPage && !itemsPerPageOptions.includes(config.defaultItemsPerPage)) {
      itemsPerPageOptions.push(config.defaultItemsPerPage)
      itemsPerPageOptions.sort((a, b) => a - b)
    }

    const props: PaginationProps = {
      current: currentPage,
      total: totalPages,
      max: 3,
      pageClickHandler: this.handlePageClick,
      itemsPerPageHandler: this.handleItemsPerPageChange,
      itemsPerPageOptions,
      itemsPerPage: itemsPerPage,
    }

    return (
      <Pagination {...props} />
    )
  }

  renderTableBody() {
    const { displayColumns } = this.state

    const paginatedRows = this.getPaginatedRows()
    const columnKeys = displayColumns.map((column: Column) => column.key)


    return (
      <tbody>
        {
          paginatedRows.map((row, rowIndex) => {
            const rowClasses = classNames({
              'table-primary': this.areRowsChecked([row]),
              [(row as ComplexRow).config?.classname || '']: (row as ComplexRow).config?.classname
            })

            return (
              <tr key={`row-${rowIndex}`} className={rowClasses}>
                {
                  !this.showRowCheckboxes ? null : (
                    <td className={'check-row'} onClick={e => this.handleCheckRows(e, [ row ])}><input type="checkbox" checked={this.areRowsChecked([row])} onChange={e => this.handleCheckRows(e, [ row ])} /></td>
                  )
                }
                {
                  displayColumns.map((cell: Column, cellIndex: number) => (
                    <td className={classNames(columnKeys[cellIndex])}>
                      { this.renderCellContent(getRowContent(row)[this.getColIndex(cell.key)], row, cell) }
                    </td>
                  ))
                }
              </tr>
            )
          })
        }
      </tbody>
    )
  }

  renderTableSummary() {
    return (
      <div className="manage-table-summary mb-3">
        <strong>{window.M.str.local_managetable.totalItems}</strong>: {this.state.rows.length}
      </div>
    )
  }

  renderCheckedinfo() {
    if (!this.state.checkedRows.length) {
      return null
    }

    return (
      <a href="#" className="checkedinfo badge badge-primary p-2" onClick={this.handleCheckedInfoClick}>
        <i className="fa fa-close"></i> {`${this.state.checkedRows.length} Einträge gewählt`}
      </a>
    )
  }

  renderBulkActions() {

    const { config } = this.props

    if (!config || !config.actions || !config.actions.bulk) {
      return null
    }

    const actions = { ...config.actions.bulk }

    Object.keys(actions).forEach(key => {
      actions[key].isDisabled = !this.state.checkedRows.length
    })

    return <ActionList actions={config.actions.bulk} handler={this.handleActions} />
  }

  renderCellContent(cell: Cell, row: Row, headerCell: Column) {
    const { config } = this.props

    if (headerCell.key === 'actions') {

      const keyColIndex = this.getKeyColIndex()

      if (!isNumber(keyColIndex)) {
        return null
      }

      const configRowActions = config.actions?.row

      let rowActions: (IAction|string)[]

      if (Array.isArray(cell.content)) {
        rowActions = cell.content as (IAction|string)[]
      }
      else {
        const rowActionMap = cell.content as ActionMap
        rowActions = this.getActionsFromMap(rowActionMap)
      }

      // Map action keys or partial objects to full action objects
      const actions = rowActions.reduce((actions, entry) => {

        if (isString(entry)) {
          const stringEntry = entry as string

          if (!configRowActions) {
            throw new Error('[ManageTable] No action entries in global config!')
          }

          if (configRowActions[stringEntry]) {
            actions[stringEntry] = configRowActions[stringEntry]
          }
          else {
            throw new Error(`[ManageTable] Action entry "${entry}" not found in config!`)
          }
        }
        else if (isObject(entry)) {
          const entryObj = entry as IAction
          const clonedEntry = { ...entryObj }

          if (entryObj.name) {
            delete clonedEntry.name
            actions[entryObj.name] = clonedEntry
          }
          else {
            throw new Error(`[ManageTable] Invalid action entry "${entry}"!`)
          }
        }
        else {
            throw new Error(`[ManageTable] Invalid action entry type "${entry}"!`)
        }

        return actions
      }, {} as ActionMap)

      const itemId = getRowContent(row)[keyColIndex].content as number

      return <ActionList itemId={itemId} actions={actions} handler={this.handleActions} />
    }

    return <div dangerouslySetInnerHTML={{ __html: !isString(cell.content) ? cell.content.toString() : cell.content }}></div>
  }
}

/**
 * Sends a request to the specified url from a form. This will change the window location.
 */
function post(path: string, params: any, method='post') {

  // The rest of this code assumes you are not using a library.
  // It can be made less wordy if you use one.
  const form = document.createElement('form')
  form.method = method
  form.action = path

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = key
      hiddenField.value = params[key]

      form.appendChild(hiddenField)
    }
  }

  document.body.appendChild(form)
  form.submit();
  document.body.removeChild(form)
}
