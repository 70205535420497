import { Component } from 'inferno'
import { createElement } from 'inferno-create-element'
import 'whatwg-fetch'

import { ManageTable } from '@components/managetable'
import { Tabs } from '@components/tabs'
import { TabContentHtml } from '@components/tabcontent_html'

type GenericObject = { [key: string]: any };

interface RootProps {
  fetchUrl: string
}

interface RootState {
  component?: string
  componentProps?: any
  isAsync?: any
  fetchUrl?: any
}

export interface DynamicProps {
  component: string
  componentProps: GenericObject
}

export type MappableComponent = "ManageTabls"|"Tabs"|"TabContentHtml"

export const componentMapping = {
  'ManageTable': ManageTable,
  'Tabs': Tabs,
  'TabContentHtml': TabContentHtml,
}

export class DynamicRoot extends Component <RootProps, RootState> {

  public state: RootState = {
  }

  constructor(props: RootProps) {
    super()
    this.loadData(props)
  }

  loadData(props: RootProps) {
    if (props.fetchUrl) {
      fetch(`${props.fetchUrl}`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin'
      })
      .then(response => response.json())
      .then((ajaxProps: DynamicProps): void => {
        this.setState({
               component:  ajaxProps.component,
          componentProps: ajaxProps.componentProps,
        })
      })
    }
  }

  render() {

    const { component, componentProps } = this.state

    if (!component) {
      return null
    }

    const ThisComponent = componentMapping[component]

    return !ThisComponent ? null : createElement(ThisComponent, componentProps)
  }

}
