import { Component } from 'inferno'
import { createElement } from 'inferno-create-element'
import classNames from 'classnames'
import isNumber from 'lodash/isNumber'
import 'whatwg-fetch'

import { MappableComponent, componentMapping } from '@components/dynamic'
import { asyncComponent } from '@components/asynccomponent'
import { TabContentHtml } from '@components/tabcontent_html'

interface Props {
  content?: Tab[]
}

interface State {
  currentIndex: number
}

interface BaseTab {
  text: string
  isActive?: boolean
  isAsync?: boolean
  fetchUrl: string
}

interface HTMLTab extends BaseTab {
  type: "html"
  content: string
}

interface ComponentTab extends BaseTab {
  type: "component"
  component: MappableComponent
  componentProps: any
}

type Tab = HTMLTab | ComponentTab

export class Tabs extends Component <Props, State> {

  public state: State = {
    currentIndex: 0
  }

  constructor(props: Props) {
    super(props)

    if (props.content && props.content.length) {
      const currentIndex = props.content.reduce((previous: null|number, current: Tab, index: number): number|null => (
        !isNumber(previous) && current.isActive ?  index : previous
      ), null)

      if (isNumber(currentIndex)) {
        this.state.currentIndex = currentIndex
      }
    }
  }

  handleTabClick(e: Event, index: number) {
    e.preventDefault()

    this.setState({
      currentIndex: index
    })
  }

  getCurrentTab() {
    return this.props.content && this.props.content.length ?
      this.props.content[this.state.currentIndex] : null
  }

  render() {
    const { currentIndex } = this.state
    const currentTab = this.getCurrentTab()

    if (!this.props.content || !this.props.content.length) {
      return null
    }

    return (
      <div>
        <ul class="nav nav-tabs">
          {
            this.props.content.map((tab, index) => (
              <li className={classNames('nav-item')}>
                <a className={classNames('nav-link', { active: currentIndex === index })} onClick={e => this.handleTabClick(e, index)}>{tab.text}</a>
              </li>
            ))
          }
        </ul>
        <div class="mt-4 tab-content">
          { !currentTab ? null : this.renderTabContent(currentTab) }
        </div>
      </div>
    )
  }

  renderTabContent(tab: Tab) {
    switch (tab.type) {
      case 'html':      return this.renderTabHtml(tab)
      case 'component': return this.renderTabComponent(tab)
      default:          return null
    }
  }

  renderTabHtml(tab: HTMLTab) {

    if (tab.isAsync) {
      return createElement(asyncComponent(TabContentHtml, {}, tab.fetchUrl))
    }

    return <TabContentHtml content={tab.content} />
  }

  renderTabComponent(tab: ComponentTab) {

    const TabComponent = componentMapping[tab.component]

    if (tab.isAsync) {
      return createElement(asyncComponent(TabComponent, {}, tab.fetchUrl))
    }

    return createElement(TabComponent, tab.componentProps)
  }

}
