import { ModalDataActionList, ModalDataAsyncHtml } from '@types';

export function showModal(data: ModalDataActionList|ModalDataAsyncHtml) {
    if (window.M.theme_soon?.events) {
        window.M.theme_soon.events.emit('MODAL_OPEN', data)
    }
  else {
    throw new Error('Event emitter not available, please make sure the newest version of theme_soon is installed.')
  }
}

export function getString(str: string, component: string = 'moodle') {
    return window.M.str[component][str]
}
