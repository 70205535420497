import { Component } from 'inferno'
import classNames from 'classnames'

export type TToastType = "info"|"warning"|"error"
export interface IToast {
  text: string
  type: TToastType
}

interface IToastWithId extends IToast {
  id: number
}

interface IState {
  toasts: IToastWithId[]
  increment: number
}

interface IProps {
  duration: number
}

export class Toaster extends Component <IProps, IState> {

  public state = {
    toasts: [],
    increment: 0
  }

  constructor() {
    super()

    this.addToast = this.addToast.bind(this)
    this.removeToast = this.removeToast.bind(this)

    window.Toaster = window.Toaster || {}
    window.Toaster.addToast = this.addToast
  }

  public addToast(toast: IToast) {
    const increment: number = this.state.increment + 1
    const newToast: IToastWithId = {
      ...toast,
      id: increment
    }
    this.setState({
      toasts: [ ...this.state.toasts, newToast ],
      increment
    })

    setTimeout(() => this.removeToast(newToast), this.props.duration * 1000)
  }

  public removeToast(toastToRemove: IToast) {
    this.setState({
      toasts: this.state.toasts.filter(toast => toast !== toastToRemove)
    })
  }

  public render() {
    return (
      <div className="toaster">
        <ul className="toaster__toasts">
          {
            this.state.toasts.map((toast: IToast) => {
              const classes = classNames('toast', `toast--${toast.type}`)
              return (
                <li className={classes}>{ toast.text }</li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}
