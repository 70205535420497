import classNames from 'classnames'
import isNumber from 'lodash/isNumber'
import {ChangeEvent} from 'inferno'

export interface Props {
  current: number
  total: number
  max?: number
  pageClickHandler: (page: 'prev'|'next'|number) => void
  itemsPerPageHandler?: (number: number) => void
  itemsPerPageOptions?: number[],
  itemsPerPage?: number
}

export const Pagination = (props: Props) => {

  let pages: number[] = []

  const lastPage = props.total-1

  if (isNumber(props.max)) {

    let iPrev = props.max
    while (iPrev) {
      if (props.current - iPrev >= 0 ) {
        pages.push(props.current - iPrev)
      }
      iPrev--
    }

    pages.push(props.current)

    let iNext = 0
    while (iNext < props.max) {
      iNext++
      if (props.current + iNext <= lastPage) {
        pages.push(props.current + iNext)
      }
    }
  }
  else {
    pages = Array.from(Array(props.total).keys())
  }

  return (
    <nav className="pagination-container py-3 d-flex justify-content-end">

      { renderPaginationOptions(props) }

      { renderPages(pages, props, lastPage) }

    </nav>
  )
}

function renderPages(pages, props, lastPage) {
  if (pages.length <= 1) {
    return null
  }

  return (
      <ul className="pagination">
      {
        props.current > 0 ? (
            <li className="page-item">
              <a onClick={() => props.pageClickHandler('prev')} className="page-link">‹ vorherige</a>
            </li>
          ) : (
            <li className="page-item disabled">
              <span className="page-link">‹ vorherige</span>
            </li>
          )
      }
      {
        isNumber(props.max) && props.current - props.max > 0 ? (
            <li className="page-item">
              <a onClick={() => props.pageClickHandler(0)} className="page-link">1</a>
            </li>
          ) : null
      }
      {
        isNumber(props.max) && props.current - (props.max + 1) > 0 ? (
            <li className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          ) : null
      }
      {
        pages.map(index => {
          let classes = classNames(
            'page-item',
            {
              'active': index == props.current
            })
          return (
            <li className={classes}>
              <a key={`page-${index}`} onClick={() => props.pageClickHandler(index)} className="page-link"> {index+1} </a>
            </li>
          )
        })
      }
      {
        isNumber(props.max) && lastPage - props.current - (props.max + 1) > 0 ? (
            <li className="page-item">
              <a onClick={() => props.pageClickHandler(lastPage)} className="page-link">...</a>
            </li>
          ) : null
      }
      {
        isNumber(props.max) && lastPage - props.current - props.max > 0 ? (
            <li className="page-item">
              <a onClick={() => props.pageClickHandler(lastPage)} className="page-link">{ props.total }</a>
            </li>
          ) : null
      }
      {
        props.current < props.total-1 ? (
            <li className="page-item">
              <a onClick={() => props.pageClickHandler('next')} className="page-link">nächste ›</a>
            </li>
          ) : (
            <li className="page-item disabled">
              <span className="page-link">nächste ›</span>
            </li>
          )
      }
      </ul>
  )
}

function renderPaginationOptions(props, ) {

  return (
    <div className="pagination-options small">
      <div className="pagination-options__items">

        <span className="pagination-options__items-description">Einträge pro Seite: </span>

        {
            !props.itemsPerPageHandler || !props.itemsPerPageOptions || !props.itemsPerPageOptions.length ? null : (
              (() => {

                const changeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
                  const value = e.target.value

                  if (!props.itemsPerPageHandler) {
                    throw new Error('ItemsPerPageHandler not defined!')
                  }

                  props.itemsPerPageHandler(parseInt(value, 10))
                }

                const itemsPerPageOptions = [ ...props.itemsPerPageOptions ]

                if (props.itemsPerPage && !itemsPerPageOptions.includes(props.itemsPerPage)) {
                  itemsPerPageOptions.push(props.itemsPerPage)
                  itemsPerPageOptions.sort(sortNumber)
                }

                return (
                  <select className="custom-select" onChange={changeHandler} name="pagination-itemsperpage">
                    {
                      itemsPerPageOptions.map(number => {
                        const value = number
                        const label = number === -1 ? "Alle anzeigen" : number

                        return (
                          <option selected={value == props.itemsPerPage} value={value}>{label}</option>
                        )
                      })
                    }
                    <option value=""></option>
                  </select>
                )
              })()
            )
        }

      </div>
    </div>
  )
}

function sortNumber(a, b) {
  return a - b;
}
