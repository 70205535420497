import classNames from 'classnames'
import { createElement } from 'inferno-create-element'
import { ModalFnParams } from '@/types'
import { getString, showModal } from '@/utilities'

export interface IAction {
  name?: string
  text?: string
  type?: string
  style?: string
  className?: string
  url?: string
  isIcon?: boolean
  icon?: string
  isPrimary?: boolean
  isSecondary?: boolean
  isDisabled?: boolean
  confirmText?: string
  data?: any
}

export type ActionMap = {
  [key: string]: IAction
}

export type IHandler = (e: Event, action: IAction, itemId?: number) => void

export type ActionListType = "buttons"|"icons"|"dropdown"

export const ActionList = ({
  itemId, actions, handler, type = "buttons"
}: {
  itemId?: number
  actions: ActionMap
  handler: IHandler
  type?: ActionListType
}) => (
  <ul className={`action-list action-list--${type} list-inline`}>
    {
      Object.keys(actions).map(key => {
        const action = actions[key]
        switch (type) {
          //case 'dropdown': return <li><ActionDropdown action={action} handler={handler} /></li>
          default:         return <li className="list-inline-item"><ActionButton itemId={itemId} name={key} action={action} handler={handler} /></li>
        }
      })
    }
  </ul>
)

export const ActionButton = ({
  itemId, name, action, handler
}: {
  itemId?: number
  name: string
  action: IAction
  handler: IHandler
}) => {
  const isDisabled = action.isDisabled === true

  const classNameEntries: any = {
    'btn': !action.isIcon,
    'btn-primary': !action.isIcon && action.isPrimary,
    'btn-secondary': !action.isIcon && action.isSecondary,
    'text-primary': action.isIcon && action.isPrimary,
    'text-secondary': action.isIcon && action.isSecondary,
    'disabled': isDisabled
  }

  if (action.className) {
    classNameEntries[`${action.className}`] =  true
  }

  const classes = classNames(classNameEntries)

  const props:{
    'data-action': string
    'data-icon'?: string
    title?: string
    onClick?: (e: Event) => void
    href?: string
  } = {
    'data-action': name,
    'data-icon': action.icon,
    title: action.text,
  }

  switch (action.type) {

    case 'modal': {
      props.href = `#${action.name}`
      props.onClick = (e) => {
        e.preventDefault()

        showModal({
          title: action.text,
          type: 'html',
          async: true,
          asyncPropsFn: async () => {
            const response = await fetch(action.url as string)
            const json = await response.json()

            if (json.errorcode) {
              return {
                title: getString('error', 'moodle'),
                type: 'html',
                content: json.error
              }
            }
            else {
              const { title, content } = json

              return {
                title, content
              }
            }
          },
        })
      }

      const text = action.isIcon ? '' : action.text

      return isDisabled ? (
        <span className={classes} {...props}>
          { getIconNode(action) } { text }
        </span>
      ) : (
        <a className={classes} {...props}>
          { getIconNode(action) } { text }
        </a>
      )
    }

    case 'actionlist': {
      props.href = `#${action.name}`
      props.onClick = (e) => {
        e.preventDefault()

        showModal({
          title: action.text,
          type: 'actionlist',
          actions: action.data.map((actionData: IAction) => ({
            name: actionData.name,
            text: actionData.text,
            fn: ({ close }: ModalFnParams, e: Event) => {
              handler(e, actionData)
              close()
            }
          }))
        })
      }

      const text = action.isIcon ? '' : action.text

      return (
          <a className={classes} {...props}>
            { action.icon ? ( <i class={`fa fa-${action.icon}`}></i> ) : null }
            { text }
          </a>
        )
    }

    default: {
      if (!isDisabled) {
        props.href = action.url
        props.onClick = (e: Event) => handler(e, action, itemId)
      }

      const text = action.isIcon ? '' : action.text

      return isDisabled ? (
        <span className={classes} {...props}>
          { getIconNode(action) } { text }
        </span>
      ) : (
        <a className={classes} {...props}>
          { getIconNode(action) } { text }
        </a>
      )
    }
  }
}

function getIconNode(action: IAction) {
  if (!action.icon) {
    return null
  }

  // Font-Awesome-Icon
  if (/^fa-/.test(action.icon)) {
    return (
      <i className={`fa ${action.icon}`}></i>
    )
  }

  // Inline SVG-Koordinaten
  else if (/^M\d+/.test(action.icon)) {
    return (
      <svg className="icon"><use xlinkHref={`#${getInlineSvgRef(action.icon)}`} /></svg>
    )
  }

  // Hinterlegtes SVG-Icon
  return (
    <svg className="icon"><use xlinkHref={`${window.M.util.image_url('icons', 'local_managetable')}#${action.icon}`} /></svg>
  )
}

// SVG-Element mit gehashter ID für Koordinaten erstellen
function getInlineSvgRef(content: string) {
  const contentHash = simpleHash(content)
  const iconId = `managetable-icon-${contentHash}`
  const iconElement = document.getElementById(iconId)

  if (iconElement) {
    return iconId
  }

  const xmlns = "http://www.w3.org/2000/svg"
  const containerId =  'manage-table-icons'
  let iconsContainer = document.getElementById(containerId) as HTMLElement|null

  if (!iconsContainer) {
    const iconsContainer = document.createElementNS(xmlns, 'svg')
    iconsContainer.id = containerId
    document.body.insertBefore(iconsContainer, document.body.firstChild)
  }

  iconsContainer = document.getElementById(containerId) as HTMLElement
  iconsContainer.style.display = 'none'

  const group = document.createElementNS(xmlns, 'g')
  const path  = document.createElementNS(xmlns, 'path')

  path.setAttributeNS(null, 'fill', 'currentColor');
  path.setAttributeNS(null, 'd', content);
  group.id = iconId
  group.appendChild(path)
  iconsContainer.appendChild(group)

  return iconId
}

// Quelle: https://gist.github.com/jlevy/c246006675becc446360a798e2b2d781
// This is a simple, *insecure* hash that's short, fast, and has no dependencies.
// For algorithmic use, where security isn't needed, it's way simpler than sha1 (and all its deps)
// or similar, and with a short, clean (base 36 alphanumeric) result.
// Loosely based on the Java version; see
// https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
function simpleHash(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return new Uint32Array([hash])[0].toString(36);
};
